export default {
  report: {
    title: "Report Generator",

    buttons: {
      export: "Export Report",
      generateReport: "Generate Report",
      cancel: "Cancel",
      advanceOptions: "Advance Options",
      applyFilter: "Apply Filters",
    },

    labels: {
      templateHelp: "Below is the report template to review. Please click the 'Generate Report' button to populate the data",
      somethingWrong: "Oops! Something went wrong, pleasy retry",
      loadingReport: "Gathering report data...",
      loadingReportTemplate: "Loading report template...",
    },

    modal: {
      title: {
        reportData: "Advance Options",
      },
      reportType: "Report Type",
      activityCreatedDate: "Activity created date",
      noLimit: "The report will include all data directly from the system, without any filters applied",
      confirmLabel: "Sure, generate now",
      cancelLabel: "Let me check",
    },
  },

  reportTemplates: {
    title: "Report Templates",
    labels: {
      title: "Report Templates",
    },
  },
}
