import ViewReport from "@/modules/report/views/ViewReport"
import ViewReportTemplates from "@/modules/report/views/ViewReportTemplates"

export default [
  {
    path: "/reports",
    component: ViewReport,
    name: "ViewReport",
    props: true,
    meta: {
      // permissions: "menu.report",
      module: "report",
    },
  },

  {
    path: "/settings/report-templates",
    component: ViewReportTemplates,
    name: "ViewReportTemplate",
    props: true,
    meta: {
      // permissions: "menu.report",
      module: "settings",
    },
  },
]
