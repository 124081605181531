<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="grid grid-cols-2 items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("reportTemplates.title") }}
        </EcHeadline>
      </EcFlex>

      <!-- action button -->
    </EcFlex>

    <!-- Viewer -->

    <EcBox class="w-full h-full">
      <EcBox class="width-full mt-4">
        <!-- certificate -->
        <EcFlex class="flex-wrap w-full max-w-full items-center justify-center">
          <EcBox class="w-1/2 mb-6 sm:pr-6">
            <RUploadFiles
              :dir="'report_templates'"
              :type="'bia_template'"
              documentTitle="BIA Report Templates"
              :isUploadOnSelect="false"
              :maxFileNum="1"
              dropZoneCls="border-c0-500 border-dashed border-2 bg-cWhite p-2 md:py-4"
              @handleSingleUploadResult="handleReportTemplateUploadResult"
              @startUploadFiles="this.isCreating = true"
              @endUploadFiles="this.isCreating = false"
            />
          </EcBox>
        </EcFlex>
      </EcBox>
    </EcBox>

    <!-- End editor-->
  </RLayout>
</template>
<script>
import { useGlobalStore } from "@/stores/global"
export default {
  name: "ViewReportTemplates",
  data() {
    return {}
  },
  setup() {
    const globalStore = useGlobalStore()
    return {
      globalStore,
    }
  },
  methods: {
    handleReportTemplateUploadResult(result) {
      this.globalStore.addSuccessToastMessage("Uploaded template successully")
    },
  },
}
</script>
