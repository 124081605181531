import { ref } from "vue"
import * as api from "../api/reportGeneratorFetcher"
import { downloadFromDirectLink } from "@/readybc/composables/helpers/downloadHelper"
import { useGlobalStore } from "@/stores/global"

export const useReportGenerator = () => {
  const globalStore = useGlobalStore()
  const reportFile = ref({})
  const isTriggeredReportGenerator = ref(false)

  /**
   *
   * @param {*} type
   * @returns
   */
  const generateReport = async (type, filters = {}) => {
    try {
      const { data } = await api.generateReport(type, filters)

      if (data && data?.uid) {
        reportFile.value = data

        const fileName = "BIA-Summary-Report-" + new Date().toISOString()
        downloadFromDirectLink(data.url, fileName, "docx")
      } else {
        reportFile.value = {}
      }

      isTriggeredReportGenerator.value = true

      return data
    } catch (error) {
      globalStore.addErrorToastMessage("Report generator has error, please contact administrator")
    }
  }

  return {
    reportFile,
    generateReport,
    isTriggeredReportGenerator,
  }
}
