import * as api from "../api/reportTemplateFetcher"

export const useReportTemplate = () => {
  const getTemplate = (type) => {
    return api.fetchTemplateByType(type)
  }

  return {
    getTemplate,
  }
}
