<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="grid grid-cols-2 items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("report.title") }}
        </EcHeadline>
      </EcFlex>

      <!-- action button -->
      <EcFlex class="flex flex-end w-full items-center w-flex-row justify-end gap-2">
        <!-- Template selector -->
        <EcBox>
          <EcSelect
            v-model="reportType"
            componentName="EcSelect"
            :options="reportTypes"
            :allowSelectNothing="false"
            :label="$t('report.modal.reportType')"
          />
        </EcBox>
        <EcBox>
          <EcButton
            v-if="reportType?.length > 0"
            class="mb-3 lg:mb-0"
            id="btn-advance-options"
            iconPrefix="Filter"
            variant="primary-sm"
            @click="
              () => {
                isModalSbuSelectOpen = true
              }
            "
          >
            {{ appliedFilterLabel }}
          </EcButton>
        </EcBox>
        <EcBox class="grid grid-cols-1 sm:grid-cols-1 gap-4 justify-end">
          <!-- generate report -->
          <EcButton
            v-if="hasTemplate && !isLoading"
            v-permissions:supplier-create
            class="mb-3 lg:mb-0"
            id="btn-generate-report"
            iconPrefix="Report"
            variant="primary-sm"
            @click="handleGenerateReport(false)"
          >
            {{ $t("report.buttons.generateReport") }}
          </EcButton>
        </EcBox>
      </EcFlex>
    </EcFlex>

    <!-- Viewer -->

    <EcBox class="w-full h-full">
      <EcFlex class="w-full items-center" v-if="!isLoading && !isTriggeredReportGenerator && reportType?.length > 0">
        <EcIcon icon="QuestionMark" width="14"></EcIcon>
        <EcLabel class="ml-2 text-sm"> {{ $t("report.labels.templateHelp") }}</EcLabel>
      </EcFlex>

      <EcFlex v-if="isLoading || isFrameLoading" class="w-full flex-col items-center justify-items-center justify-center">
        <EcSpinner></EcSpinner>
        <EcText class="mt-2">
          {{ isTriggeredReportGenerator ? $t("report.labels.loadingReport") : $t("report.labels.loadingReportTemplate") }}
        </EcText>
      </EcFlex>

      <!-- EcFlex background -->
      <EcBox class="w-full mt-10" v-if="reportType?.length <= 0">
        <EcFlex class="flex-col w-full vh-[70%] items-center justify-center">
          <EcText class="text-base">Please choose a <b>Report Type</b> to load the appropriate template</EcText>
          <img class="w-[40%] mt-10" src="@/assets/images/report_bg.svg" />
        </EcFlex>
      </EcBox>

      <!-- Using office 365 viewer to see the office files -->
      <EcFlex v-if="reportFile?.uid && !isLoading && reportType?.length > 0" class="w-full h-[100vh] mt-4 mb-4">
        <iframe :ref="iframeRef" style="width: 99%; color: #fff" :src="src" frameBorder="0" @load="handleFrameLoad"> </iframe>
      </EcFlex>

      <EcFlex v-if="!reportFile?.uid && isTriggeredReportGenerator" class="w-full justify-center">
        {{ $t("report.labels.somethingWrong") }}
      </EcFlex>
    </EcBox>

    <!-- End editor-->
  </RLayout>

  <!-- Modal Delete -->
  <teleport to="#layer1">
    <ModalReportSbuSelection
      :isModalOpen="isModalSbuSelectOpen"
      @close="handleCloseModalSbuSelection"
      @handleApplyFilters="handleApplyFilters"
    >
    </ModalReportSbuSelection>
  </teleport>
  <Teleport to="#layer2">
    <EcPopConfirm
      v-model="isShowWarning"
      :title="$t('report.modal.noLimit')"
      :confirmLabel="$t('report.modal.confirmLabel')"
      @onConfirm="handleGenerateReport(true)"
    ></EcPopConfirm>
  </Teleport>
</template>
<script>
import ModalReportSbuSelection from "@/modules/report/components/ModalReportSbuSelection"
import { useReportTemplate } from "../use/useReportTemplate"
import { useModalReportSbuSelection } from "../use/useModalReportSbuSelection"

import { reactive, ref } from "vue"
import { useReportGenerator } from "../use/useReportGenerator"
import { useI18n } from "vue-i18n"

export default {
  name: "ViewReport",
  data() {
    return {
      isLoading: false,
      isFrameLoading: false,
      hasTemplate: false,
      iframeRef: ref(),
      isModalSbuSelectOpen: false,
      reportType: "",
      filterData: reactive({}),
      appliedFilterLabel: this.$t("report.buttons.advanceOptions"),
      isShowWarning: false,
    }
  },
  setup() {
    const { getTemplate } = useReportTemplate()
    const { reportFile, generateReport, isTriggeredReportGenerator } = useReportGenerator()
    const { isGeneratingReport, reportTypes } = useModalReportSbuSelection()
    const { t } = useI18n()

    return {
      getTemplate,
      isGeneratingReport,
      reportFile,
      generateReport,
      isTriggeredReportGenerator,
      reportTypes,
      t,
    }
  },

  computed: {
    /**
     * Iframe SRC
     */
    src() {
      const encodedUrl = encodeURIComponent(this.reportFile?.url)

      return `https://view.officeapps.live.com/op/view.aspx?src=${encodedUrl}&wdOrigin=BROWSELINK`
    },
  },
  methods: {
    handleFrameLoad() {
      this.isFrameLoading = false
    },

    /**
     * fetch suppliers
     * @returns {Promise<void>}
     */
    async fetchTemplates(type) {
      this.isLoading = true

      const template = await this.getTemplate(type)

      if (template && template?.data) {
        this.reportFile = template?.data
      }

      this.hasTemplate = true
      this.isLoading = false
    },

    /**
     * fetch suppliers
     * @returns {Promise<void>}
     */
    async handleGenerateReport(force = false) {
      if (
        !force &&
        (!this.filterData?.selectedSBUs || this.filterData?.selectedSBUs?.length <= 0) &&
        (!this.filterData?.activityCreatedAtObj?.start || this.filterData?.activityCreatedAtObj?.start?.length <= 0) &&
        (!this.filterData?.activityCreatedAtObj?.end || this.filterData?.activityCreatedAtObj?.end?.length <= 0)
      ) {
        this.isShowWarning = true
        return
      }

      this.isTriggeredReportGenerator = true
      this.isLoading = true
      this.handleCloseModalSbuSelection()
      await this.generateReport("bia", this.filterData)
      this.isLoading = false
    },

    /**
     * Appy filters
     */
    handleApplyFilters(filterData) {
      this.filterData = filterData

      this.advanceOptionsLabel()
    },

    /**
     * close modal
     */
    handleCloseModalSbuSelection() {
      this.isModalSbuSelectOpen = false
    },

    /**
     * Advance option label
     */
    advanceOptionsLabel() {
      let appliedFilterCnt = 0

      if (this.filterData?.selectedSBUs?.length > 0) {
        appliedFilterCnt++
      }
      if (this.filterData?.activityCreatedAtObj?.start?.length > 0 || this.filterData?.activityCreatedAtObj?.end?.length > 0) {
        appliedFilterCnt++
      }

      if (appliedFilterCnt > 0) {
        this.appliedFilterLabel = `Applied ${appliedFilterCnt} filter(s)`
      } else {
        this.appliedFilterLabel = this.t("report.buttons.advanceOptions")
      }
    },
  },

  components: { ModalReportSbuSelection },

  watch: {
    reportType(val) {
      if (val?.length > 0) {
        this.fetchTemplates(val)
      }
    },
  },
}
</script>
