import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

export const fetchSupplierList = async (params) => {
  return fetcher.get(`identity/api/v1/suppliers`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const fetchTemplateByType = async (type) => {
  return fetcher.get(`identity/api/v1/reports/templates/${type}`)
}
